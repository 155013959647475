import { IEnum_Componentsolutionpropsvendorresourceexternal_Documenttype } from 'src/graphql/generated/hooks'
import { FilterOption, TaxonomyType } from 'src/types'

export const basicPlanFeatures = [
  'Compare products',
  'Create a list of followed solutions',
  // 'Leave ratings and reviews',
]

export const premiumPlanFeatures = [
  // 'Compare products based on 100+ different fields',
  // 'View premium insights',
  // 'View buyers’ guides',
  // 'View testimonials',
  // 'View screenshots and videos',
  'Access to Solution Guides',
  'Access to Premium Knowhow',
  'Access to Vendor Analyses',
  'Access to Legaltech Resources for Lawyers',
]

export const vendorPlanFeatures = [
  'Create listings',
  'Claim and update existing listings',
  'Upgrade your listings',
  'Communicate with buyers',
]

export const vendorResourceFilters: FilterOption = {
  title: TaxonomyType.ResourceType,
  data: [
    { name: 'Article', id: IEnum_Componentsolutionpropsvendorresourceexternal_Documenttype.Article },
    { name: 'Case Study', id: IEnum_Componentsolutionpropsvendorresourceexternal_Documenttype.CaseStudy },
    { name: 'Checklist', id: IEnum_Componentsolutionpropsvendorresourceexternal_Documenttype.Checklist },
    {
      name: 'Practical Guidance',
      id: IEnum_Componentsolutionpropsvendorresourceexternal_Documenttype.PracticalGuidance,
    },
    { name: 'Press Release', id: IEnum_Componentsolutionpropsvendorresourceexternal_Documenttype.PressRelease },
    { name: 'White Paper', id: IEnum_Componentsolutionpropsvendorresourceexternal_Documenttype.WhitePaper },
    { name: 'Product Briefing', id: IEnum_Componentsolutionpropsvendorresourceexternal_Documenttype.ProductBriefing },
  ],
}

export const fundingInfosC = [
  { value: 'N_A', label: 'N/A' },
  { value: 'Other', label: 'Other' },
  { value: 'Pre_Seed', label: 'Pre Seed' },
  { value: 'Seed', label: 'Seed' },
  { value: 'Series_A', label: 'Series A' },
  { value: 'Series_B', label: 'Series B' },
  { value: 'Series_C', label: 'Series C' },
  { value: 'Series_D', label: 'Series D' },
]
export const fundingInfo = ['N_A', 'Other', 'Pre_Seed', 'Seed', 'Series_A', 'Series_B', 'Series_C', 'Series_D']

export const fundingAmountsC = [
  { value: 'N_A', label: 'N/A' },
  { value: 'Less_than_One_M', label: '< $1M' },
  { value: 'One_To_Five_M', label: '$1 - 5M' },
  { value: 'Five_To_Ten_M', label: '$5 - 10M' },
  { value: 'Ten_To_TwentyFive_M', label: '$10 - 25M' },
  { value: 'TwentyFive_To_Fifty_M', label: '$25 - 50M' },
  { value: 'Fifty_To_Hundred_M', label: '$50 - 100M' },
  { value: 'More_Than_Hundred_M', label: '> $100M' },
]

export const LTH_SOLUTION_MENU = [
  {
    slg: '/lth-solution/law-firms-in-house', //, //
    title: 'LTH Premium for law firms and in-house',
    containerid: 'law-firms-in-house',
    portalId: '21422664',
    formId: 'e6764df8-2bdd-451a-8614-84bfa06f71c9',
    externalLink: 'https://hub.legaltechnologyhub.com/firm-premium-home',
    height: '2750px',
  },
  {
    slg: '/lth-solution/solution-providers', //, //
    title: 'LTH Premium for Solution Providers',
    containerid: 'solution-providerse',
    portalId: '21422664',
    formId: 'e6764df8-2bdd-451a-8614-84bfa06f71c9',
    externalLink: 'https://hub.legaltechnologyhub.com/vendor-premium-home',
    height: '3100px',
  },
  {
    slg: '/lth-solution/investors-others', //'', //
    title: 'LTH for Investors and others',
    containerid: 'investors-others',
    portalId: '21422664',
    formId: 'e6764df8-2bdd-451a-8614-84bfa06f71c9',
    externalLink: 'https://hub.legaltechnologyhub.com/investors-home',
    height: '2700px',
  },
  /* {
    slg: 'lth-solution/legaltech-resources-for-lawyers',
    title: 'Legaltech Resources for Lawyers',
    containerid: 'legaltech-resources-for-lawyers',
    portalId: '21422664',
    formId: 'e6764df8-2bdd-451a-8614-84bfa06f71c9',
    height: '3100px',
  }, */
  {
    slg: '/lth-solution/vendor-advisory', //'', //
    title: 'LTH Vendor Advisory',
    containerid: 'vendor-advisory',
    portalId: '21422664',
    formId: 'e6764df8-2bdd-451a-8614-84bfa06f71c9',
    externalLink: 'https://hub.legaltechnologyhub.com/vendor-advisory-home',
    height: '2700px',
  },
  {
    slg: '/lth-solution/law-firm-advisory', //'', //
    title: 'LTH Law Firm Advisory',
    containerid: 'law-firm-advisory',
    portalId: '21422664',
    formId: 'e6764df8-2bdd-451a-8614-84bfa06f71c9',
    externalLink: 'https://hub.legaltechnologyhub.com/firm-advisory-home',
    height: '2800px',
  },
]

export const INSIGHTS_MENU = [
  {
    slg: '/blogs/lth-product-briefings/', // 'insights/product-briefing',
    title: 'LTH Product Briefing',
    containerid: 'product-briefing',
    portalId: '21422664',
    formId: 'e6764df8-2bdd-451a-8614-84bfa06f71c9',
    target: '',
  },
  {
    slg: '/blogs/the-vendor-cheatsheet/', // 'insights/law-firms',
    title: 'The Vendor Cheatsheet',
    containerid: 'law-firms',
    portalId: '21422664',
    formId: 'e6764df8-2bdd-451a-8614-84bfa06f71c9',
    target: '',
  },
  {
    slg: '/blogs/lth-insights-for-law-firms/', // 'insights/law-firms',
    title: 'LTH Insights for Law Firms',
    containerid: 'law-firms',
    portalId: '21422664',
    formId: 'e6764df8-2bdd-451a-8614-84bfa06f71c9',
    target: '',
  },
  {
    slg: '/blogs/lth-insights-for-corporate-legal/', // 'insights/corporate-legal',
    title: 'LTH Insights for Corporate Legal',
    containerid: 'corporate-legal',
    portalId: '21422664',
    formId: 'e6764df8-2bdd-451a-8614-84bfa06f71c9',
    target: '',
  },
  {
    slg: '/blogs/jean-ogrady-for-lth/', // 'insights/jean-o-grady',
    title: 'Jean O’Grady for LTH',
    containerid: 'jean-o-grady',
    portalId: '21422664',
    formId: 'e6764df8-2bdd-451a-8614-84bfa06f71c9',
    target: '',
  } /* 
  {
    slg: '/blogs/clm-simplified-for-lth/', // 'insights/clm-simplified',
    title: 'CLM Simplified for LTH',
    containerid: 'clm-simplified',
    portalId: '21422664',
    formId: 'e6764df8-2bdd-451a-8614-84bfa06f71c9',
    target: '',
  }, */,
  {
    slg: 'https://hub.legaltechnologyhub.com/subscribe',
    title: 'Subscribe to Insights',
    containerid: 'subscribe-to-insights',
    portalId: '21422664',
    formId: 'e6764df8-2bdd-451a-8614-84bfa06f71c9',
    target: '_blank',
  },
]
