import cn from 'classnames'
import Image from 'next/image'
import { PremiumTag } from 'src/components/global/PremiumTag/PremiumTag'
import { VendorTag } from 'src/components/global/VendorTag/VendorTag'
import { SHOW_VENDOR_PORTAL } from 'src/config/config'

import LogoDarkSVG from '../../assets/dark_bg_logo.svg'
import LogoLightSVG from '../../assets/light_bg_logo.svg'

interface LogoProps {
  /**
   * mode for dark and light
   */
  mode?: 'dark' | 'light'
  className?: string
  isPremium?: boolean
  isVendor?: boolean
}

const Logo: React.FC<LogoProps> = ({ mode = 'dark', className, isPremium = false, isVendor = false }) => {
  return (
    <div className={cn('cursor-pointer flex items-center')}>
      <div className={cn('flex items-center', className)}>
        <Image src={mode === 'dark' ? LogoDarkSVG : LogoLightSVG} className="w-full h-full" alt="" />
      </div>
      {isPremium && !isVendor && <PremiumTag className="ml-[6px]" />}
      {SHOW_VENDOR_PORTAL && isVendor && <VendorTag className="ml-[6px]" />}
    </div>
  )
}

export default Logo
