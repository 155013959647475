import { Menu, Transition } from '@headlessui/react'
import IonIcon from '@reacticons/ionicons'
import cn from 'classnames'
//import Link from 'next/link'
import React, { Fragment, useEffect, useState } from 'react'
import { LabelLink } from 'src/components/global/LabelLink'
// import { INSIGHTS_MENU } from 'src/constants/constants'
//import * as enVariables from 'src/config/config'

//import styles from './Header.module.scss'

/**
 * Primary UI component for user interaction
 */

interface MenuProps {
  isOpen: boolean
}
export const EventsMenu: React.FC<MenuProps> = ({ isOpen }) => {
  const [menuOpen, setMenuOpen] = useState(isOpen)
  //const [topicCurrentIdx, setTopicCurrentIdx] = useState(0)

  useEffect(() => {
    // eslint-disable-next-line no-console
    setMenuOpen(isOpen)
  }, [isOpen])

  return (
    <Menu as="div" className={cn('relative inline-block h-full w-[90px] ml-[5px] mr-[10px] cursor-pointer text-left')}>
      {({ open }) => (
        <>
          <Menu.Button
            type="button"
            className={cn(
              'inline-flex justify-center gap-1 items-center w-full text-white font-secondary text-[0.875rem] h-full font-bold',
              { 'bg-white bg-opacity-5': open },
            )}
          >
            Events
            <IonIcon
              className="text-white mr-0 !important"
              name={menuOpen ? 'chevron-up-outline' : 'chevron-down-outline'}
            />
          </Menu.Button>
          {menuOpen && (
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                static
                className="z-30 overflow-hidden flex flex-row w-[190px] h-[7rem] rounded-[1.25rem] shadow-lg bg-white absolute"
                style={{ top: '40px' }}
              >
                <div className="absolute py-4 w-[190px] h-full overflow-y-auto">
                  <ul>
                    <li className="px-3 h-10 w-full">
                      <LabelLink
                        href="https://lthconferences.legaltechnologyhub.com"
                        className="font-secondary font-medium text-[0.875rem] text-left px-6 w-full inline-flex items-center h-full text-textdark bg-white rounded-full hover:bg-turquoise hover:bg-opacity-10"
                        label="LTH Events"
                        target={'_blank'}
                      />
                    </li>
                    <li className="px-3 h-10 w-full">
                      <LabelLink
                        href="/events/"
                        className="font-secondary font-medium text-[0.875rem] text-left px-6 w-full inline-flex items-center h-full text-textdark bg-white rounded-full hover:bg-turquoise hover:bg-opacity-10"
                        label="Events Calendar"
                      />
                    </li>
                  </ul>
                </div>
              </Menu.Items>
            </Transition>
          )}
        </>
      )}
    </Menu>
  )
}
