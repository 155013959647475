import IonIcon from '@reacticons/ionicons'
//import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { MouseEvent, useState } from 'react'
import * as enVariables from 'src/config/config'
import { INSIGHTS_MENU, LTH_SOLUTION_MENU } from 'src/constants/constants'
import { ITopicItemFragment } from 'src/graphql/generated/hooks'
import { useAuthenticated } from 'src/hooks/useAuthenticated'

//import { CompatibleLink } from '../CompatibleLink/CompatibleLink'
import { LabelLink } from './LabelLink'
import { PremiumModal } from './PremiumModal'

export interface SlidingbarProps {
  className?: string
  topics: ITopicItemFragment[]
  options: MenuItem[]
  onClosePanel: () => void
  onSignout: () => void
  onSelectMenu: (title: string) => void
}

export interface MenuItem {
  title: string
  subMenuContained: boolean
}

export const Slidingbar: React.FC<SlidingbarProps> = ({ options, topics, onSignout, onClosePanel }) => {
  const router = useRouter()

  const [selectedMenu, setSelectedMenu] = useState('')
  const [selectedTopic, setSelectedTopic] = useState('')
  //const [selectedTopicSlug, setSelectedTopicSlug] = useState('')
  //const [selectedSubTopics, setSelectedSubTopics] = useState<ISubTopicItemFragment[]>([])

  // eslint-disable-next-line no-console
  console.log(topics.length)

  const lthSolutionMenu = LTH_SOLUTION_MENU
  const lthInsightsMenu = INSIGHTS_MENU
  const { isPremium } = useAuthenticated()
  const [showModal, setShowModal] = useState(false)

  const handleSelectMenu = (e: MouseEvent<HTMLDivElement>, title: string) => {
    /* if (title === 'Events') {
      router.push({ pathname: '/events' })
    } else */ if (title === 'About Us') {
      router.push({ pathname: '/about' })
    } else if (title === 'Legaltech Jobs') {
      window.open(enVariables.JOB_URL)
    } else if (title === 'Sign In') {
      router.push({ pathname: '/signin' })
    } else if (title === 'Sign Up') {
      router.push({ pathname: '/signup' })
    } else {
      setSelectedMenu(title)
    }
  }

  const handleSignoutAndRedirect = () => {
    onSignout()
    router.push({ pathname: '/' })
  }

  const handleMenuBack = () => {
    if (selectedTopic === '') {
      setSelectedMenu('')
    } else {
      setSelectedTopic('')
    }
  }

  const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
    if (!isPremium) {
      e.preventDefault() // Prevent the navigation
      //router.push('/login') // Redirect to login
      //setShowModal(true)
      onClosePanel()
      setShowModal(true)
    }
  }
  const handleCloseModal = () => {
    setShowModal(false)
  }

  return (
    <div style={{ backgroundColor: '#011d58', height: '100%', zIndex: '10000' }}>
      <div className="flex flex-col p-[1.5625rem]" style={{ backgroundColor: '#011d58' }}>
        {selectedMenu === '' && (
          <div className="flex flex-col w-full" style={{ backgroundColor: '#011d58' }}>
            <div className="flex ml-auto" onClick={onClosePanel}>
              <IonIcon name="close-outline" className="!h-[30px] !w-[30px] text-[white]" />
            </div>
            <hr className="mt-[1.87652rem]" style={{ opacity: '0.3' }} />
            {options.map((option: MenuItem, index: number) => {
              return (
                <div key={index} onClick={(e: MouseEvent<HTMLDivElement>) => handleSelectMenu(e, option.title)}>
                  <div className="flex flex-row h-[2.5rem] items-center">
                    <p className="text-[white] font-secondary text-[0.875rem]">{option.title}</p>
                    {option.subMenuContained && (
                      <IonIcon name="chevron-forward-outline" className="!w-[20px] !h-[20px] ml-auto text-[white]" />
                    )}
                  </div>
                  <hr className="" style={{ opacity: '0.3' }} />
                </div>
              )
            })}
          </div>
        )}

        {/* {selectedMenu === 'Topics' && (
          <div style={{ backgroundColor: '#011d58' }}>
            <div className="flex flex-row">
              <div className="flex flex-col">
                <div className="flex flex-row items-center" onClick={handleMenuBack}>
                  <IonIcon name="chevron-back-outline" className="!w-[20px] !h-[20px] text-[white]" />
                  <p className="text-[white] font-secondary text-[0.75rem] cursor-pointer">Back</p>
                </div>
                <p className="text-[white] font-secondary font-bold text-[0.875rem] mt-[1.2875rem]">
                  {selectedTopic === '' ? 'TOPICS' : selectedTopic}
                </p>
              </div>

              <div className="flex ml-auto" onClick={onClosePanel}>
                <IonIcon name="close-outline" className="!h-[30px] !w-[30px] text-[white] cursor-pointer" />
              </div>
            </div>

            <hr className="mt-[0.87652rem]" style={{ opacity: '0.3' }} />
            {selectedTopic === '' ? (
              topics.map((topic, index) => {
                return (
                  <div className="flex flex-col w-full" key={index}>
                    <div className="flex flex-row h-[2.5rem]">
                      <CompatibleLink href={'/topics/' + topic.attributes?.slug} className="flex items-center w-full">
                        <IonIcon
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          name={topic.attributes?.iconName as any}
                          className="!w-[20px] !h-[20px] text-[white] mr-[0.875rem]"
                        />
                        <p className="text-[white] font-secondary text-[0.875rem]">{topic.attributes?.name}</p>
                        <div
                          className="ml-auto w-[60px] h-[40px] flex items-center justify-end"
                          onClick={() => {
                            setSelectedTopic(topic.attributes?.name || '')
                            setSelectedTopicSlug(topic.attributes?.slug || '')
                            setSelectedSubTopics(topic.attributes?.subTopics?.data || [])
                          }}
                        >
                          <IonIcon name="chevron-forward-outline" className="!w-[20px] !h-[20px] text-[white]" />
                        </div>
                      </CompatibleLink>
                    </div>
                    <hr style={{ opacity: '0.3' }} />
                  </div>
                )
              })
            ) : (
              <>
                {selectedSubTopics.map((topic, index) => {
                  return (
                    <div className="flex flex-col w-full" key={index}>
                      <div className="flex flex-row h-[2.5rem] items-center">
                        <Link
                          href={`/topics/${selectedTopicSlug}/${topic.attributes?.slug}`}
                          className="flex flex-row w-full"
                        >
                          <p className="text-[white] font-secondary text-[0.875rem]">{topic.attributes?.name}</p>
                        </Link>
                      </div>
                      <hr style={{ opacity: '0.3' }} />
                    </div>
                  )
                })}
                <div className="flex flex-col w-full">
                  <div className="flex flex-row h-[2.5rem] items-center">
                    <Link
                      href={`/topics/${selectedTopicSlug}`}
                      className="flex flex-row w-full text-[white] font-secondary text-[0.875rem]"
                    >
                      View All
                    </Link>
                  </div>
                  <hr style={{ opacity: '0.3' }} />
                </div>
              </>
            )}
          </div>
        )} */}
        {selectedMenu === 'LTH Solutions' && (
          <div style={{ backgroundColor: '#011d58' }}>
            <div className="flex flex-row">
              <div className="flex flex-col">
                <div className="flex flex-row items-center" onClick={handleMenuBack}>
                  <IonIcon name="chevron-back-outline" className="!w-[20px] !h-[20px] text-[white]" />
                  <p className="text-[white] font-secondary text-[0.75rem] cursor-pointer">Back</p>
                </div>
                <p className="text-[white] font-secondary font-bold text-[0.875rem] mt-[1.2875rem]">LTH Solutions</p>
              </div>

              <div className="flex ml-auto" onClick={onClosePanel}>
                <IonIcon name="close-outline" className="!h-[30px] !w-[30px] text-[white] cursor-pointer" />
              </div>
            </div>

            <hr className="mt-[0.87652rem]" style={{ opacity: '0.3' }} />

            {lthSolutionMenu &&
              lthSolutionMenu.map((item: any, index: number) => {
                return (
                  <div className="flex flex-col w-full">
                    <div className="flex flex-row h-[2.5rem] items-center">
                      <LabelLink
                        key={'solution-' + index}
                        href={item?.slg}
                        label={item?.title}
                        className="text-[white] font-secondary text-[0.875rem]"
                        onClick={onClosePanel}
                      />
                    </div>
                    <hr style={{ opacity: '0.3' }} />
                  </div>
                )
              })}
          </div>
        )}
        {selectedMenu === 'Insights' && (
          <div style={{ backgroundColor: '#011d58' }}>
            <div className="flex flex-row">
              <div className="flex flex-col">
                <div className="flex flex-row items-center" onClick={handleMenuBack}>
                  <IonIcon name="chevron-back-outline" className="!w-[20px] !h-[20px] text-[white]" />
                  <p className="text-[white] font-secondary text-[0.75rem] cursor-pointer">Back</p>
                </div>
                <p className="text-[white] font-secondary font-bold text-[0.875rem] mt-[1.2875rem]">Insights</p>
              </div>

              <div className="flex ml-auto" onClick={onClosePanel}>
                <IonIcon name="close-outline" className="!h-[30px] !w-[30px] text-[white] cursor-pointer" />
              </div>
            </div>

            <hr className="mt-[0.87652rem]" style={{ opacity: '0.3' }} />

            {lthInsightsMenu &&
              lthInsightsMenu.map((item: any, index: number) => {
                return (
                  <div className="flex flex-col w-full">
                    <div className="flex flex-row h-[2.5rem] items-center">
                      <LabelLink
                        key={'solution-' + index}
                        href={item?.slg}
                        label={item?.title}
                        className="text-[white] font-secondary text-[0.875rem]"
                        onClick={onClosePanel}
                      />
                    </div>
                    <hr style={{ opacity: '0.3' }} />
                  </div>
                )
              })}
          </div>
        )}
        {selectedMenu === 'Events' && (
          <div style={{ backgroundColor: '#011d58' }}>
            <div className="flex flex-row">
              <div className="flex flex-col">
                <div className="flex flex-row items-center" onClick={handleMenuBack}>
                  <IonIcon name="chevron-back-outline" className="!w-[20px] !h-[20px] text-[white]" />
                  <p className="text-[white] font-secondary text-[0.75rem] cursor-pointer">Back</p>
                </div>
                <p className="text-[white] font-secondary font-bold text-[0.875rem] mt-[1.2875rem]">Events</p>
              </div>

              <div className="flex ml-auto" onClick={onClosePanel}>
                <IonIcon name="close-outline" className="!h-[30px] !w-[30px] text-[white] cursor-pointer" />
              </div>
            </div>

            <hr className="mt-[0.87652rem]" style={{ opacity: '0.3' }} />

            <div className="flex flex-col w-full">
              <div className="flex flex-row h-[2.5rem] items-center">
                <LabelLink
                  href="https://lthconferences.legaltechnologyhub.com"
                  label="LTH Events"
                  className="text-[white] font-secondary text-[0.875rem]"
                  onClick={onClosePanel}
                />
              </div>
              <hr style={{ opacity: '0.3' }} />
            </div>
            <div className="flex flex-col w-full">
              <div className="flex flex-row h-[2.5rem] items-center">
                <LabelLink
                  href="/events/"
                  label="Events Calendar"
                  className="text-[white] font-secondary text-[0.875rem]"
                  onClick={onClosePanel}
                />
              </div>
              <hr style={{ opacity: '0.3' }} />
            </div>
          </div>
        )}

        {selectedMenu === 'My Account' && (
          <div style={{ backgroundColor: '#011d58' }}>
            <div className="flex flex-row">
              <div className="flex flex-col">
                <div className="flex flex-row items-center" onClick={handleMenuBack}>
                  <IonIcon name="chevron-back-outline" className="!w-[20px] !h-[20px] text-[white]" />
                  <p className="text-[white] font-secondary text-[0.75rem] cursor-pointer">Back</p>
                </div>
                <p className="text-[white] font-secondary font-bold text-[0.875rem] mt-[1.2875rem]">
                  {selectedTopic === '' ? 'My Account' : selectedTopic}
                </p>
              </div>

              <div className="flex ml-auto" onClick={onClosePanel}>
                <IonIcon name="close-outline" className="!h-[30px] !w-[30px] text-[white] cursor-pointer" />
              </div>
            </div>

            <hr className="mt-[0.87652rem]" style={{ opacity: '0.3' }} />

            <div className="flex flex-col w-full">
              <div className="flex flex-row h-[2.5rem] items-center">
                <LabelLink
                  href="/my-account/"
                  label="My Account"
                  className="text-[white] font-secondary text-[0.875rem]"
                />
              </div>
              <hr style={{ opacity: '0.3' }} />
            </div>
            <div className="flex flex-col w-full">
              <div className="flex flex-row h-[2.5rem] items-center">
                <LabelLink
                  href="/my-followings"
                  label="Followed Solutions"
                  className="text-[white] font-secondary text-[0.875rem]"
                />
              </div>
              <hr style={{ opacity: '0.3' }} />
            </div>
            {/* <div className="flex flex-col w-full">
              <div className="flex flex-row h-[2.5rem] items-center">
                <LabelLink
                  href="/my-reviews"
                  label="My reviews"
                  className="text-[white] font-secondary text-[0.875rem]"
                />
              </div>
              <hr style={{ opacity: '0.3' }} />
            </div> */}
            <div className="flex flex-col w-full">
              <div className="flex flex-row h-[2.5rem] items-center">
                <div className="text-[white] font-secondary text-[0.875rem]" onClick={handleSignoutAndRedirect}>
                  Sign Out
                </div>
              </div>
              <hr style={{ opacity: '0.3' }} />
            </div>
          </div>
        )}
        {selectedMenu === 'Industry Analysis' && (
          <div style={{ backgroundColor: '#011d58' }}>
            <div className="flex flex-row">
              <div className="flex flex-col">
                <div className="flex flex-row items-center" onClick={handleMenuBack}>
                  <IonIcon name="chevron-back-outline" className="!w-[20px] !h-[20px] text-[white]" />
                  <p className="text-[white] font-secondary text-[0.75rem] cursor-pointer">Back</p>
                </div>
                <p className="text-[white] font-secondary font-bold text-[0.875rem] mt-[1.2875rem]">
                  INDUSTRY ANALYSIS
                </p>
              </div>

              <div className="flex ml-auto" onClick={onClosePanel}>
                <IonIcon name="close-outline" className="!h-[30px] !w-[30px] text-[white] cursor-pointer" />
              </div>
            </div>

            <hr className="mt-[0.87652rem]" style={{ opacity: '0.3' }} />

            <div className="flex flex-col w-full">
              <div className="flex flex-row h-[2.5rem] items-center">
                <LabelLink
                  href="/graveyard-consolidations/?tab=consolidations/"
                  label="Consolidations"
                  className="text-[white] font-secondary text-[0.875rem]"
                  onClick={handleClick}
                />
              </div>
              <hr style={{ opacity: '0.3' }} />
            </div>
            <div className="flex flex-col w-full">
              <div className="flex flex-row h-[2.5rem] items-center">
                <LabelLink
                  href="/graveyard-consolidations/?tab=graveyard"
                  label="Graveyard"
                  className="text-[white] font-secondary text-[0.875rem]"
                  onClick={handleClick}
                />
              </div>
              <hr style={{ opacity: '0.3' }} />
            </div>

            {/* <div className="flex flex-col w-full">
              <div className="flex flex-row h-[2.5rem] items-center">
                <LabelLink href="/blogs/" label="Blogs" className="text-[white] font-secondary text-[0.875rem]" />
              </div>
              <hr style={{ opacity: '0.3' }} />
            </div>
            <div className="flex flex-col w-full">
              <div className="flex flex-row h-[2.5rem] items-center">
                <LabelLink
                  href="/solution-guides/"
                  label="Solution Guides"
                  className="text-[white] font-secondary text-[0.875rem]"
                />
              </div>
              <hr style={{ opacity: '0.3' }} />
            </div>
            <div className="flex flex-col w-full">
              <div className="flex flex-row h-[2.5rem] items-center">
                <LabelLink href="/know-how" label="Know-How" className="text-[white] font-secondary text-[0.875rem]" />
              </div>
              <hr style={{ opacity: '0.3' }} />
            </div>
            <div className="flex flex-col w-full">
              <div className="flex flex-row h-[2.5rem] items-center">
                <LabelLink
                  href="/legaltech-resources-for-lawyers/"
                  label="Legaltech Resources for Lawyers"
                  className="text-[white] font-secondary text-[0.875rem]"
                />
              </div>
              <hr style={{ opacity: '0.3' }} />
            </div> */}
            {/* <div className="flex flex-col w-full">
              <div className="flex flex-row h-[2.5rem] cursor-pointer items-center">
                <LabelLink
                  href="/graveyard-consolidations/"
                  label="Graveyard & Consolidations"
                  className="text-[white] font-secondary text-[0.875rem]"
                />
              </div>
              <hr style={{ opacity: '0.3' }} />
            </div> */}
          </div>
        )}
      </div>
      <PremiumModal origin="/graveyard-consolidations/" isOpen={showModal} onClose={handleCloseModal} />
    </div>
  )
}
